// Avoid `console` errors in browsers that lack a console.
(function () {
  var method;
  var noop = function () {
  };
  var methods = [
    'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
    'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
    'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
  ];
  var length = methods.length;
  var console = (window.console = window.console || {});

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
}());

const inlineSVG = require('inline-svg');
const anime = require('animejs/lib/anime');

//
(function () {

  inlineSVG.init({
    svgSelector: 'img.svg', // the class attached to all images that should be inlined
    initClass: 'js-inlinesvg', // class added to <html>
  }, () => {
    let logo = document.getElementById('logo');
    let animation = anime.timeline({
      autoplay: true,
      loop: true,
      easing: 'easeOutExpo',
    });
    let logoChildren = logo.children;
    let tagList = ['metadata', 'style', 'defs', 'sodipodi:namedview'];
    for (let i = 0; i < logoChildren.length; i += 1) {
      console.log(logoChildren[i]);
      let tag = logoChildren[i].tagName;

      if (tagList.indexOf(tag) === -1) {
        animation.add({
          targets: logoChildren[i],
          opacity: 0.5,
          duration: 50,
        });
        animation.add({
          targets: logoChildren[i],
          opacity: 1,
          duration: 100,
        });
      }
    }
  })
}());

